/* Container styling */
.container {
    background-color: #afafaf;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
}

/* Header section styling */
.header-section {
    margin-bottom: 30px;
}

.header-section h1 {
    font-size: 2.5rem;
    color: #333;
    font-weight: bold;
}

.header-section h3 {
    font-size: 1.5rem;
    color: #666;
    font-weight: 300;
}

.header-section p {
    font-size: 1.2rem;
    color: #777;
    margin-top: 10px;
}

/* Product previews styling */
.product-previews {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.product-card {
    width: 250px;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.product-card h4 {
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    margin: 10px 0;
}

.product-card p {
    font-size: 1rem;
    color: #666;
    font-weight: 300;
}

/* Footer section styling */
.footer-section {
    margin-top: 20px;
}

.store-button {
    background-color: #1a73e8;
    color: #fff;
    padding: 15px 30px;
    font-size: 1.2rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.store-button:hover {
    background-color: #155bb5;
}
