.home-container {
  background-color: #082b46;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: auto;
  color: white;
  width: 80%;
  margin: auto;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.main-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin: 1%;
}

.home-header {
  display: flex;
  text-align: center;
}
.home-image {
  max-height: 250px;
  min-width: 50%;
  max-width: 100%;
  border-radius: 15px;
}

.home-content {
  width: 80%;
  text-align: center;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 1%;
}

.arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
}

.list-container {
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin: 1%;
}

.button-container {
  width: 80%;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Change flex-direction to column if width is below 500px */
/* @media (max-width: 500px) {
} */

/* Change flex-direction to row if width is greater than 500px */
/* @media (min-width: 501px) {
  .img-container {
    flex-direction: row;
  }
} */

/* Logo Effects*/

@keyframes jump {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.logo-name {
  color: white;
  transition: all 0.3s ease;
  display: inline-block;
  cursor: pointer;
  animation: jump 0.6s ease infinite;
}

.logo-name span {
  display: inline-block;
}

/* Apply to each letter */
.logo-name span:nth-child(odd) {
  animation-delay: 0.1s;
}
.logo-name span:nth-child(even) {
  animation-delay: 0.2s;
}

@keyframes repeat-animation {
  0% {
  }
  100% {
  }
}

.gradient-button {
  background: linear-gradient(45deg, #105992, #082b46); /* Gradient background */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-flex; /* Changed to inline-flex */
  align-items: center; /* Vertically center text */
  justify-content: center; /* Horizontally center text */
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
}

.gradient-button:hover {
  background: linear-gradient(45deg, #1b9bff, #082b46); /* Change gradient on hover */
  transform: translateY(-3px); /* Lift the button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@media (max-width: 460px) {
  .gradient-button {
    padding: 10px; /* Adjust padding for small screens */
    font-size: 14px; /* Optional: Adjust font size for better fit */
  }
}
@media (max-width: 400px) {
  .gradient-button {
    padding: 5px; /* Adjust padding for small screens */
    font-size: 14px; /* Optional: Adjust font size for better fit */
  }
}

