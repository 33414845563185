small {
  font-size: xx-small ;
}
.m-0 {
  margin: 0;
}
.logger-container {
  margin-top: 100px;
  background-color: #082b46;
  flex-direction: column;
  color: white;
  width: 80%;
  margin: auto;
  border-radius: 30px;
  text-align: center;
  display: flex;
  justify-content: flex-start; /* Prevents content from being vertically centered */
  align-items: center;
  min-height: 80vh; /* Allow it to stretch as content grows */
  overflow: hidden; /* Prevents overflow from breaking layout */
}




#masterList {
  flex-grow: 1; /* Allow it to expand within the parent flex container */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Make sure it takes full width */
  padding: 10px;
  background-color: #082b46;
  border-radius: 10px;
  max-height: calc(100vh - 300px); /* Set max height based on viewport minus margins */
  box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}


.hidden {
  display: none;
}

.invisible{
  opacity: 0;
}

.exercise-set {
  display: flex;
  background-color: #008cba;
  margin:  2px;
  border-radius: 5px;
  border: 3px solid #646464;
}

.form-inputs {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}
.input-1 {
  width: 90%;
  margin-bottom: 4%;
}
@media (max-width: 720px) {
  .exercise-set {
    flex-direction: column;
    width: 100%;
  }
  .md-label {
    margin-top: 5%;
    margin-bottom: 2%;
  }
  .input-container {
    width: 100%;
  }
  .btn-container {
    flex-direction: column;
  }
  .logger-header {
    font-size: smaller;
  }
}
@media (min-width: 721px) {
  .exercise-set {
    flex-direction: row;
    width: 100%;
  }
  .md-label {
    margin-top: 1%;
  }
  .input-container {
    width: 50%;
  }
  .input-container-sm {
    width: 25%;
  }
}

@media (max-height: 1000px ) {
  .logger-container {
    min-height: 70%;
  }
}
.input-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-wrap: nowrap;
  font-size: smaller;
}

.input-container-2 {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%
}

.ts-form {
  background-color:rgba(255, 255, 255, 0.1);
}
/* Apply a consistent height and width to all buttons and inputs within .input-container */
.input-container input,
.input-container button {
  width: 100%; /* Make sure the width fills the parent container */
  max-width: 300px; /* Set a maximum width if you don't want them to grow indefinitely */
  height: 40px; /* Set a consistent height */
  padding: 5px;
  box-sizing: border-box; /* Ensure padding doesn't affect width/height */
  border-radius: 5px; /* Optional: Add rounded corners */
  font-size: 16px; /* Ensure consistent font size */
  text-align: center;
  font-weight: bolder;
  align-self: center;
}

.text-lg {
  font-weight: bolder !important;
  font-size: 1.5rem !important;
}

/* Optional: Add hover and focus effects to inputs and buttons */
.input-container input:hover,
.input-container button:hover {
  outline: none;
  border-color: #007bff; /* Change border color on focus */
  background-color: #3a3a3a; /* Light background color on hover */
  color: white;
}
.input-container input:focus
{
background-color: #007bb5;
color: white;
}
.btn-container {
  display: flex;
  justify-content: center;
  transition: 500ms ease;
}

.set-btns {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;
  font-weight: 800;
  transition: 400ms ease;
}

/* New Set Button */
.btn-new-set {
  background-color: #4caf50; /* Green */
  color: white;
  border-radius: 5px;
}

.btn-new-set:hover {
  background-color: #45a049;
}
/* New Cardio Set Button*/
.btn-new-cardio-set {
  background-color: #0e523b; /* Darker Green */
  color: white;
  border-radius: 5px;
}

.btn-new-cardio-set:hover {
  background-color: #07810d;
}

/* Duplicate Set Button */
.btn-dupe-set {
  background-color: #008cba; /* Blue */
  color: white;
}

.btn-dupe-set:hover {
  background-color: #007bb5;
}

/* Additional Button Styles */

/* Delete Set Button */
.btn-delete-set {
  background-color: #f44336; /* Red */
  color: white;
}

.btn-delete-set:hover {
  background-color: #da190b;
}

/* Save Set Button */
.btn-save-set {
  background-color: #ff9800; /* Orange */
  color: white;
  transition: 500ms ease;
  transform: scale(0.9);
  font-size: 1.5rem;
}

.btn-save-set:hover {
  background-color: #5f3a03;
  transform: scale(1);
}

/* Reset Set Button */
.btn-reset-set {
  background-color: #9c27b0; /* Purple */
  color: white;
}

.btn-reset-set:hover {
  background-color: #7b1fa2;
}

.btn-description {
  background-color: rgb(25, 101, 152);
  color: white;
}

.btn-description:hover {
  background-color: rgb(16, 67, 101);
}

/* Hide the default file input */
.btn-upload-set {
  display: none;
}

/* Style the label to look like a button */
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #1c87c9; /* Button background color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

/* Hover effect */
.custom-file-upload:hover {
  background-color: #145a86; /* Darker shade for hover effect */
}

/* Add margin to match the layout of other buttons */
.custom-file-upload {
  margin-top: 10px;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically */
  font-size: 1rem;
}

/* Optional: make the file name visible after selecting */
.file-name {
  font-size: 14px;
  margin-top: 5px;
  color: #f0f0f0;
}

.ts-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ts-form-2 {
  align-items: unset !important;
}

/* Help Popup*/

.description-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #011728;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.description-popup h3 {
  margin-top: 0;
}

.description-popup ul {
  list-style-type: none;
  padding-left: 0;
}

.description-popup li {
  margin-bottom: 10px;
}

.btn-close-description {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn-close-description:hover {
  background-color: #da190b;
}

.btn-download-set {
  background-color: #9d9d9d;
}

.btn-download-set:hover {
  background-color: #787878;
}
/* Fade-out animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

.btn-ts-1 {
  background-color: #fbeee0;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: 500ms ease;
  align-self: center;
}

.btn-ts-1:hover {
  background-color: #113046;
  color: rgb(195, 154, 99);
}

.btn-ts-1:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .btn-ts-1 {
    min-width: 120px;
    padding: 0 25px;
  }
}

.btn-resize-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.false-btn {
  width: 100%;
  height: 100%;
}

.logger-header {
  margin: 1%;
}

.timer {
  color: white;
  display: block;  /* Change to block instead of fixed */
  margin: 10px 0;  /* Add margin for spacing */
  opacity: 1;
  transition: opacity 500ms ease;
}

.btn-timer {
  display: inline-block;  /* Change to inline-block for better button styling */
  margin-top: 10px;  /* Add margin to separate it from the timer */
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  font-size: 16px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px 20px;  /* Ensure proper padding for the button */
  text-align: center;  /* Center text inside the button */
}

.btn-timer:hover {
  background: rgba(255, 255, 255, 0.3);  /* Lighten background on hover */
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
  transform: translateY(-3px);
}

.btn-timer:active {
  transform: translateY(1px);
}

.cardio-set {
  display: flex;
  background-color: #ffcc00;
  margin: 2px;
  border-radius: 5px;
  border: 3px solid #646464;
  padding: 10px;
  width: 100%;
}

.cardio-input {
  width: 100%;
  padding: 5px;
  margin: 5px;
}

/* WebKit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #2c2c2c; /* Dark background for the scrollbar track */
  border-radius: 5px; /* Rounded edges for the track */
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded edges for the scrollbar handle */
  border: 2px solid #2c2c2c; /* Padding around the thumb to give it some space */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888; /* Lighter color when hovering over the scrollbar */
}

/* Firefox */
* {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: #555 #2c2c2c; /* Thumb color and track color */
}

/* Optional: Remove scrollbar from input and textarea */
input::-webkit-scrollbar,
textarea::-webkit-scrollbar {
  display: none;
}
