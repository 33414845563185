.nav-container {
  transition: ease 500ms;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #011728;
  flex-grow: 1;
  max-height: 150px;
  align-content: center;
}
.nav-container:hover {
  background-color: #205781;
}
#navImage {
  border-radius: 45%;
  max-height: 150px;
  flex-shrink: 1;
  transition: ease 800ms;
}

.nav-resizer {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
}

.right {
  float: right;
}
.flexbox-container {
  display: flex;
  flex-direction: row; 
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  opacity: 1; /* Default state with full opacity */
  transition: opacity 700ms ease, transform 700ms ease; /* Smoother transition */
}

.visible {
  opacity: 1;
  transform: translateY(0); /* Initial position */
  transition: opacity 700ms ease-in, transform 700ms ease-in; /* Smooth fade-in */
}
.flexbox-container2 {
  flex-direction: row;
}
.flexbox-item {
  flex: 1 1 200px; /* Grow, shrink, and set a base width of 200px */
  max-width: 200px; /* Optional max-width */
  text-align: center;
}


.flexbox-item-1 {
  text-align: center;
}

.flexbox-item-2 {
  text-align: center;
}
.flexbox-item-3 {
  text-align: center;
}

.image-container {
  display: flex;
  transition: ease 500ms;
  align-self: center;
  justify-content: flex-end;
}

a {
  text-decoration: none;
  color: #333;
}

.link {
  transition: ease 500ms;
  opacity: 1;
}
.link:hover {
  transform: scale(1.05);
}

.hidden {
  opacity: 0;
  transform: translateX(-300px);
  transition: ease 1000ms;
}

.hidden2 {
  opacity: 0;
  transform: translateY(-20px); /* Shorter translate distance for a smoother effect */
  transition: opacity 500ms ease-out, transform 500ms ease-out 200ms; /* Delay to avoid abruptness */
}

.height-sm {
  max-height: 50px;
}
.btn-ts-1 {
  background-color: #fbeee0;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: 500ms ease;
  align-self: center;
}

.btn-ts-1:hover {
  background-color: #113046;
  color: rgb(195, 154, 99);
}

.btn-ts-1:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.navbar-toggle-btn {
  transform: scale(0.7);
}
@media (min-width: 768px) {
  .btn-ts-1 {
    min-width: 120px;
    padding: 0 25px;
  }
}

@media (max-width: 768px) {
  .flexbox-container {
    flex-direction: column; /* Stack items vertically */
    padding: 5px; /* Reduce padding for smaller screens */
  }
}


.logo-brand {
  margin-top: 2%;
  border-radius: 15%;
  color: white;
  margin: auto;
}

.logo-brand:hover {
  transform: scale(1.1);
}

.mini-logo {
  align-self: center;
  justify-self: center;
  max-height: 150px;
}
